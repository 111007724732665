<template>
    <v-card flat tile rounded>
        <v-row>
            <v-col cols="6" md="9">
                <!-- <span class="mb-2"><strong>Recipients options</strong></span> -->
                <v-text-field v-model="search" :prepend-inner-icon="icons.mdiMagnify" class="search-field" hide-details
                    rounded dense outlined clearable>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <!-- <span class="mb-2"><strong>Recipients options</strong></span> -->
                <v-select :items="recipienttypeitem" label="Filter options" @change="filtersRecipientsItems" solo
                    hide-details rounded>
                </v-select>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
        </v-row>
        <v-card-text>

        </v-card-text>

        <v-data-table v-model="tempSelectedData" :loading="this.isRecipientLoaded === true"
            loading-text="Loading... Please wait" :headers="headers" :search="search" :items="sortRecipientsItems"
            item-key="no" show-select :single-select="singleSelect">
            <template v-slot:item.name="{ item }">
                <div class="d-flex flex-column">
                    <span>{{ item.name }}</span>
                    <span v-if="item.isProfessionnal === true"
                        class="mr-auto text-caption rounded-lg subsecondary secondary--text px-2">Professionnal</span>
                    <span v-else class="mr-auto text-caption rounded-lg subprimary primary--text px-2">Enterprise</span>
                </div>
            </template>
            <template v-slot:item.phone="{ item }">
                <span>{{ item.phone }}</span>
            </template>
            <template v-slot:item.email="{ item }">
                <span>{{ item.email }}</span>
            </template>
            <template v-slot:item.num="{ item }" class="vhowhide" v-show="false">
                <span width="2px" class="vhowhide mr-auto">{{ item.no }}</span>
            </template>
        </v-data-table>

        <v-card-text>
            <v-row class="d-flex flex-row flex-wrap">
                <v-spacer></v-spacer>
                <v-col cols="12" md="4">
                    <v-btn :loading="loadingClose" block type="reset" color="error" outlined class="py-5"
                        @click="closeSelectRecipient">
                        Close
                    </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="ml-0">
                    <v-btn :loading="loadingSentReq" block color="secondary" class="py-5"
                        @click="sendeRequestToRecipient">
                        Send to recipient
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll } from '@mdi/js'
// import md5 from '@md5'
var md5 = require("md5")
import { Drequest } from '@/plugins/Drequest'
import { integer } from 'vuelidate/lib/validators'

export default {
    props: {
        type: {
            type: String,
            default: "rfc"
        },
        reqkey: {
            type: integer,
            default: -1
        }
    },
    data() {
        return {
            loading: false,
            loadingClose: false,
            loadingSentReq: false,
            isRecipientLoaded: true,
            recipientIndex: 1,
            listOfrecipients: [],
            tempSelectedData: [],
            selectedCompany: [],
            selectedUser: [],
            singleSelect: false,
            roottype: ((this.$route.path).substr(0, "/dashc".length).toUpperCase() == "/dashc".toUpperCase()) ? 'company' : 'user'
        }
    },
    methods: {
        filtersRecipientsItems(value) {
            if (value === this.recipienttypeitem[1]) {
                return this.listOfrecipients.sort((a, b) => ((a.type > b.type) ? -1 : 1));
            }
            else if (value === this.recipienttypeitem[2]) {
                return this.listOfrecipients.sort((a, b) => ((a.type > b.type) ? 1 : -1));
            }
            else {
                return this.listOfrecipients.sort((a, b) => (a.name > b.name ? 1 : -1));
            }
        },
        closeSelectRecipient() {
            this.loadingClose = true
            var tempRequestQuery = `rfc.delete?id=${this.$store.getters.getTids}`
            if (this.type === 'rfp') {
                tempRequestQuery = `rfp.delete?id=${this.$store.getters.getTids}`
            }
            Drequest.api(tempRequestQuery)
                .get((response) => {
                    if (response.success == true) {
                        this.loadingClose = false
                        this.$emit('closeselection', true)
                    }
                    else {
                        this.loadingClose = false
                        this.$fire({
                            type: "error",
                            text: "Unable to cancel operation",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    this.loadingClose = false
                    this.$fire({
                        type: "error",
                        text: "Error occured",
                        timer: 3000
                    })
                })
        },
        sendeRequestToRecipient() {
            var successUser = false;
            var successCompany = false;

            var bodyRfcUser = { "rfcuser_bulk": [] }
            var bodyRfcEnterprise = { "rfcenterprise_bulk": [] }
            var bodyRfpEnterprise = { "rfpenterprise_bulk": [] }

            this.loadingSentReq = true;
            this.tempSelectedData.forEach((value, index) => {
                if (value.isProfessionnal === true) {
                    if (this.type === 'rfp') {
                        bodyRfpEnterprise.rfpenterprise_bulk.push({
                            "user.id": value.indexk,
                            "rfp.id": this.$store.getters.getTids,
                            "comment": ''
                        })
                    }
                    else {
                        bodyRfcUser.rfcuser_bulk.push({
                            "user.id": value.indexk,
                            "rfc.id": this.$store.getters.getTids,
                        })
                    }
                }
                if (value.isProfessionnal === false) {
                    if (this.type === 'rfp') {
                        bodyRfpEnterprise.rfpenterprise_bulk.push({
                            "enterprise.id": value.indexk,
                            "rfp.id": this.$store.getters.getTids,
                            "comment": ''
                        })
                    }
                    else {
                        bodyRfcEnterprise.rfcenterprise_bulk.push({
                            "enterprise.id": value.indexk,
                            "rfc.id": this.$store.getters.getTids,
                        })
                    }
                }
            })

            // alert(this.$store.getters.getTids)
            if (this.type === 'rfp') {
                Drequest.api("rfpenterprise.sent")
                    .data(bodyRfpEnterprise)
                    .raw((response) => {
                        if (response.success == true) {
                            this.loadingSentReq = false;
                            this.$fire({
                                type: "success",
                                text: "Request has been sent",
                                timer: 3000
                            })
                            this.$router.push({ name: 'company.rfp.outbox', params: { company: this.$store.getters.getCompany.name } })
                        }
                        else {
                            this.loadingSentReq = false;
                            this.$fire({
                                type: "error",
                                text: "Error occured",
                                timer: 3000
                            })

                        }
                    })
                    .catch((err) => {
                        this.loadingSentReq = false;
                        alert("Error occured")
                    })

            }
            else {
                if (bodyRfcUser.rfcuser_bulk.length > 0) {
                    Drequest.api("rfcuser.sent")
                        .data(bodyRfcUser)
                        .raw((response) => {
                            if (response.success == true) {
                                this.loadingSentReq = false;
                                successUser = true
                                if ((bodyRfcEnterprise.rfcenterprise_bulk.length < 0)) {
                                    this.$fire({
                                        type: "success",
                                        text: "Request has been sent",
                                        timer: 3000
                                    })
                                    if (this.roottype === 'company') {
                                        this.$router.push({ name: 'company.rfp.outbox', params: { company: this.$store.getters.getCompany.name } })
                                    } else {
                                        this.$router.push({ name: 'user.rfp.outbox' })
                                    }
                                }
                            }
                            else {
                                this.loadingSentReq = false;
                                if ((bodyRfcEnterprise.rfcenterprise_bulk.length < 0)) {
                                    this.$fire({
                                        type: "error",
                                        text: "Error occured",
                                        timer: 3000
                                    })
                                }
                                successUser = false
                            }
                        })
                        .catch((err) => {
                            this.loadingSentReq = false;
                        })
                }
                if (bodyRfcEnterprise.rfcenterprise_bulk.length > 0) {
                    Drequest.api("rfcenterprise.sent")
                        .data(bodyRfcEnterprise)
                        .raw((response) => {
                            if (response.success == true) {
                                this.loadingSentReq = false;
                                this.$fire({
                                    type: "success",
                                    text: "Request has been sent",
                                    timer: 3000
                                })
                                successCompany = true
                                if (this.roottype === 'company') {
                                    this.$router.push({ name: 'company.rfp.outbox', params: { company: this.$store.getters.getCompany.name } })
                                } else {
                                    this.$router.push({ name: 'user.rfp.outbox' })
                                }
                            }
                            else {
                                this.loadingSentReq = false;
                                this.$fire({
                                    type: "error",
                                    text: "Error occured",
                                    timer: 3000
                                })
                                successCompany = false
                            }
                        })
                        .catch((err) => {
                            this.loadingSentReq = false;
                        })
                }
            }
        },
    },
    mounted() {
        this.isRecipientLoaded = true
        const tmp_id = this.$store.getters.getCompany.id
        Drequest.api(`lazyloading.user`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.listOfrecipients.push({
                            no: this.recipientIndex,
                            indexk: value.id,
                            name: value.firstname,
                            phone: value.phonenumber,
                            email: value.email,
                            logo: value.logo,
                            isProfessionnal: true,
                            type: 'Professionnal'
                        })
                        this.recipientIndex = this.recipientIndex + 1
                    })
                    this.isRecipientLoaded = false

                    this.filtersRecipientsItems("No filter")
                }
                else {
                    this.isRecipientLoaded = false;
                }
            })
            .catch((err) => {
                this.isRecipientLoaded = false;
            })
        Drequest.api(`lazyloading.enterprise`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.listOfrecipients.push({
                            no: this.recipientIndex,
                            indexk: value.id,
                            name: value.name,
                            phone: value.telephone,
                            email: value.email,
                            logo: value.logo,
                            isProfessionnal: false,
                            type: 'Enterprise'
                        })
                        this.recipientIndex = this.recipientIndex + 1
                    })
                    this.isRecipientLoaded = false

                    this.filtersRecipientsItems("No filter")
                }
                else {
                    this.isRecipientLoaded = false;
                }
            })
            .catch((err) => {
                this.isRecipientLoaded = false;
            })
    },
    computed: {
        sortRecipientsItems() {
            // return this.listOfrecipients.sort((a, b) => (a.name > b.name ? 1 : -1));
            return this.listOfrecipients
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    setup(props) {
        const btnSingle = ref(false)
        const btnRow = ref(false)
        const gettingData = props.listData
        const rfc_k = props.reqkey
        const title = props.title
        const icons = { mdiMagnify, mdiEye, mdiShareAll }
        let search = ref('')
        const headers = [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
            },
            { text: 'Phone number', value: 'phone' },
            { text: 'Email', value: 'email' },
            { text: '', value: 'num' },
            // { text: 'View', value: 'actions', sortable: false },
        ]

        const recipientDataTable = ref([])
        const recipienttypeitem = [
            'Alls',
            'Professionals',
            'Companies'
        ]
        return {
            btnRow, btnSingle, recipienttypeitem,
            // 
            recipientDataTable,
            gettingData, rfc_k,
            title, icons,
            search, headers
        }
    },
}
</script>

<style lang="scss" scoped>
//@import '@/styles/styles.scss';

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}

.vhowhide {
    width: 2px;
    min-width: 2px;
    max-width: 2px;
    display: none;
}
</style>