var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":"","rounded":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"9"}},[_c('v-text-field',{staticClass:"search-field",attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"hide-details":"","rounded":"","dense":"","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.recipienttypeitem,"label":"Filter options","solo":"","hide-details":"","rounded":""},on:{"change":_vm.filtersRecipientsItems}})],1)],1),_c('v-card-text'),_c('v-data-table',{attrs:{"loading":this.isRecipientLoaded === true,"loading-text":"Loading... Please wait","headers":_vm.headers,"search":_vm.search,"items":_vm.sortRecipientsItems,"item-key":"no","show-select":"","single-select":_vm.singleSelect},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.name))]),(item.isProfessionnal === true)?_c('span',{staticClass:"mr-auto text-caption rounded-lg subsecondary secondary--text px-2"},[_vm._v("Professionnal")]):_c('span',{staticClass:"mr-auto text-caption rounded-lg subprimary primary--text px-2"},[_vm._v("Enterprise")])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"item.num",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"vhowhide mr-auto",attrs:{"width":"2px"}},[_vm._v(_vm._s(item.no))])]}}]),model:{value:(_vm.tempSelectedData),callback:function ($$v) {_vm.tempSelectedData=$$v},expression:"tempSelectedData"}}),_c('v-card-text',[_c('v-row',{staticClass:"d-flex flex-row flex-wrap"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"py-5",attrs:{"loading":_vm.loadingClose,"block":"","type":"reset","color":"error","outlined":""},on:{"click":_vm.closeSelectRecipient}},[_vm._v(" Close ")])],1),_c('v-col',{staticClass:"ml-0",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"py-5",attrs:{"loading":_vm.loadingSentReq,"block":"","color":"secondary"},on:{"click":_vm.sendeRequestToRecipient}},[_vm._v(" Send to recipient ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }