<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark v-bind="attrs" v-on="on">
                Submit
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5">Confirm your submission</span>
            </v-card-title>
            <v-card-text>
                <v-text-field label="Title" :value="wjdksfjkdkhogdfdjodfodpigdigd" type="text" outlined dense readonly
                    placeholder="Title"></v-text-field>
                <v-text-field v-model="objects" label="Title" type="text" outlined dense readonly placeholder="Title">
                </v-text-field>
                <v-text-field v-model="titles" label="Object" type="text" outlined dense readonly
                    placeholder="Construction d'un centre hospitalier">
                </v-text-field>
                <v-textarea v-model="contents" label="Body" outlined dense readonly
                    placeholder="Lorem ipsum dolor sit amet. Non magnam asperiores in libero sint ut dolores voluptatum et"
                    class="text-left mb-3"></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
    }),

    // setup() {
    //     const types = ref('ddd')
    //     const objects = ref('ddd')
    //     const titles = ref('ddd')
    //     const contents = ref('ddd')
    //     let dialog = false
    //     return {
    //         types,
    //         objects,
    //         titles,
    //         contents,
    //         dialog
    //     }
    // },

    // props: {
    //     type: {
    //         type: String,
    //         default: ''
    //     },
    //     object: {
    //         type: String,
    //         default: ''
    //     },
    //     title: {
    //         type: String,
    //         default: ''
    //     },
    //     content: {
    //         type: String,
    //         default: ''
    //     },
    // },
    // setup() {
    //     const types = props.type
    //     const objects = props.object
    //     const titles = props.title
    //     const contents = props.content
    //     return {
    //         types,
    //         objects,
    //         titles,
    //         contents,
    //     }
    // },
}
</script>